import { combineReducers } from 'redux'
// ** Reducers Imports
// ** IMPORTAÇÕES NATIVAS DO VUEXY, VOU DEIXAR AQUI PARA APAGAR CADA ARQUIVO DEPOIS (comentário)
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
//** IMPORTAÇÕES DO PROJETO PORTAL-DE-COMPRAS= 
import AdminDashboardReducer from './reducers/AdminDashboardReducer'
import BrandsReducer from './reducers/BrandsReducer'
import CategoriesReducer from './reducers/CategoriesReducer'
import ColorsReducer from './reducers/ColorsReducer'
import DealershipReducer from './reducers/DealershipReducer'
import EstimativeReducer from './reducers/EstimativeReducer'
import OrdersReducer from './reducers/OrdersReducer'
import RentalDashboardReducer from './reducers/RentalDashboardReducer'
import RentalsReducer from './reducers/RentalsReducer'
import UsersReducer from './reducers/UsersReducer'
import VehiclesReducer from './reducers/VehiclesReducer'
import {
  EstimativesJanReducer,
  EstimativesFevReducer,
  EstimativesMarReducer,
  EstimativesAbrReducer,
  EstimativesMaiReducer,
  EstimativesJunReducer,
  EstimativesJulReducer,
  EstimativesSetReducer,
  EstimativesAgoReducer,
  EstimativesOutReducer,
  EstimativesNovReducer,
  EstimativesDezReducer
} from './reducers/MonthlyEstimativeReducer'
import {
  OrderJanReducer,
  OrderFevReducer,
  OrderMarReducer,
  OrderAbrReducer,
  OrderMaiReducer,
  OrderJunReducer,
  OrderJulReducer,
  OrderSetReducer,
  OrderAgoReducer,
  OrderOutReducer,
  OrderNovReducer,
  OrderDezReducer
} from './reducers/MonthlyOrderReducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  kanban,
  navbar,
  layout,
  invoice,
  ecommerce,
  dataTables,
  permissions,
  //** STORES PROJETO DE COMPRAS (comentário) */
  admindashboard: AdminDashboardReducer,
  brands: BrandsReducer,
  categories: CategoriesReducer,
  colors: ColorsReducer,
  dealership: DealershipReducer,
  estimative: EstimativeReducer,
  orders: OrdersReducer,
  rentaldashboard: RentalDashboardReducer,
  rentals: RentalsReducer,
  users: UsersReducer,
  vehicles: VehiclesReducer,
  estimativesjan: EstimativesJanReducer,
  estimativesfev: EstimativesFevReducer,
  estimativesmar: EstimativesMarReducer,
  estimativesabr: EstimativesAbrReducer,
  estimativesmai: EstimativesMaiReducer,
  estimativesjun: EstimativesJunReducer,
  estimativesjul: EstimativesJulReducer,
  estimativesago: EstimativesAgoReducer,
  estimativesset: EstimativesSetReducer,
  estimativesout: EstimativesOutReducer,
  estimativesnov: EstimativesNovReducer,
  estimativesdez: EstimativesDezReducer,
  ordersjan: OrderJanReducer,
  ordersfev: OrderFevReducer,
  ordersmar: OrderMarReducer,
  ordersabr: OrderAbrReducer,
  ordersmai: OrderMaiReducer,
  ordersjun: OrderJunReducer,
  ordersjul: OrderJulReducer,
  ordersago: OrderAgoReducer,
  ordersset: OrderSetReducer,
  ordersout: OrderOutReducer,
  ordersnov: OrderNovReducer,
  ordersdez: OrderDezReducer
})

export default rootReducer
