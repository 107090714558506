const initialState = {
  data: []
}

export const OrderJanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_JAN': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderFevReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_FEV': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderMarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_MAR': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderAbrReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_ABR': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderMaiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_MAI': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderJunReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_JUN': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderJulReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_JUL': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderAgoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_AGO': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_SET': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_OUT': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderNovReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_NOV': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const OrderDezReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDERS_DEZ': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
