const initialState = {
  data: []
}

export const EstimativesJanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_JAN': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesFevReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_FEV': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesMarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_MAR': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesAbrReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_ABR': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesMaiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_MAI': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesJunReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_JUN': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesJulReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_JUL': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesAgoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_AGO': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_SET': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_OUT': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesNovReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_NOV': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}

export const EstimativesDezReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ESTIMATIVES_DEZ': {
      return { ...state, data: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
